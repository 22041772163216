import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utility/api/flightApi";
import api from "@src/utility/api";
import toast, { Toaster } from "react-hot-toast";

const customAxios = axios.create({
    baseURL: 'https://localhost:54166',
});

export const fetchAll = createAsyncThunk("setting/fetchAll", async () => {
    const response = await api.get('/settings');
    return response.data;
});

export const update = createAsyncThunk(
    "settings",
    async ({ values }, { rejectWithValue }) => {
        try {
            const response = await api.put(`/settings`, values);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.errors);
        }
    }
);

const settingSlice = createSlice({
    name: "setting",
    initialState: {
        loading: false,
        error: null,
        settingOptions: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                state.loading = false;
                state.settingOptions = action.payload;
            })
            .addCase(update.fulfilled, (state, action) => {
                const { successCallBack } = action.meta.arg;
                state.loading = false;
                state.error = null;
                const { type } = action;

                if (successCallBack) {
                    successCallBack(action.payload);
                }
            })
            .addCase(update.rejected, (state, action) => {
                const { failCallBack } = action.meta.arg;
                failCallBack && failCallBack(action.payload?.data || {});
                state.loading = false;
                state.error = action.payload;
            })
            .addMatcher(
                (action) => action.type.endsWith("/update/fulfilled"),
                (state, action) => {
                    state.loading = false;
                    if (!action.error) {
                        toast.success('Settings updated successfully!', {
                            duration: 3000,
                        });
                        state.error = null;
                    }
                    const { type } = action;

                }
            )
            .addMatcher(
                (action) => action.type.endsWith("/pending"),
                (state) => {
                    state.loading = true;
                    state.error = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith("/rejected"),
                (state, action) => {
                    state.loading = false;
                    state.errors = action.payload;
                    if (action.payload?.status === 400) {
                        toast.error(
                            action.payload?.data?.errorMessage ??
                            "Somthing went wronge please try again later..."
                        );
                    }
                }
            );
    },
});

export default settingSlice.reducer;